<template>
  <div class="content">
    <el-card shadow="never" class="new-card">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">账号管理</div>
        </div>
      </div>
      <div class="card-header-left">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="员工姓名">
            <el-input
              v-model="formInline.username"
              size="small"
              placeholder="请输入员工姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="钉钉工号">
            <el-input
              v-model="formInline.jobNumber"
              size="small"
              placeholder="请输入钉钉工号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" size="small"
              >搜索</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              :loading="btnLoading"
              @click="synchronize"
              size="small"
              >同步账号</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card shadow="never" class="new-card" style="margin-top: 24px">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">账号列表</div>
        </div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table :data="tableData">
          <el-table-column
            label="员工姓名"
            prop="username"
            align="left"
            width="240"
          />
          <el-table-column
            label="钉钉号"
            prop="jobNumber"
            align="left"
            width="160"
          />
          <el-table-column label="角色" prop="roleNameStr" align="center" />
          <el-table-column label="操作" align="center" width="170">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.row.id)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div
          style="
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 30px;
          "
        >
          <el-pagination
            :current-page="page.current"
            :page-sizes="page.sizes"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
    <el-dialog title="编辑账号" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="ruleForm">
        <el-form-item label="员工姓名">
          <el-input
            v-model="form.username"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="钉钉工号">
          <el-input
            v-model="form.jobNumber"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="角色授权">
          <br />
          <el-checkbox-group
            v-model="haveRole"
            @change="(val) => handleCheckedCitiesChange(val)"
          >
            <el-checkbox
              v-for="(item, index) in roleAll"
              :label="index"
              :key="index"
              >{{ item }}({{ index }})</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from "@/store";
export default {
  data() {
    return {
      btnLoading: false,
      formInline: {
        jobNumber: "",
        username: "",
      },
      cities: ["上海", "北京", "广州", "深圳"],
      form: {
        jobNumber: "",
        username: "",
        id: "",
      },
      multipleSelection: [],
      tableData: [], // 表格数据
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
      tableHeight: "850px",
      dialogFormVisible: false,
      roleAll: {},
      random: 0,
      haveRole: [],
    };
  },
  created() {
    this.getUserList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 210 + "px";

    // 全选的数据
    this.oneDimensionalList = [];
  },
  methods: {
    synchronize() {
      this.btnLoading = true;

      this.axios
        .get("SYN_USER", {})
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "同步成功",
            });
            this.getUserList();
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((item, index) => {
        this.multipleSelection.push(item.id);
      });
    },
    // 打开编辑
    handleEdit(items) {
      this.dialogFormVisible = true;
      this.axios
        .post("USER_BYID", {
          id: items,
        })
        .then((result) => {
          console.log("用户列表", result);
          this.roleAll = result.data.roleAll;
          console.log(this.roleAll);
          this.form.username = result.data.username;
          this.form.jobNumber = result.data.jobNumber;
          this.form.id = result.data.id;
          let haveRole = result.data.haveRole;
          this.haveRole = [];
          if (haveRole) {
            for (let key in haveRole) {
              this.haveRole.push(key);
            }
          }
        });
      //   this.$router.push(`/detail/${items}`);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let haveRole = this.haveRole;
          let data = {
            distributionRole: haveRole,
          };

          let param = { ...this.form, ...data };
          this.axios.post("USER_UPDATE", param).then((result) => {
            if (result.code == 0) {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.getUserList();
            }
          });
          //   this.$router.push(`/detail/${items}`);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.dialogFormVisible = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(this.haveRole);

      // let checkedCount = value;
      // // this.checkAll = checkedCount === this.cities.length;
      // value.forEach((i)=>{
      //     console.log(i)

      // })
      // // this.form.haveRole =
    },
    // 删除角色
    handleDelete(id) {
      this.$confirm("您确定要删除角色么？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = [...new Set(this.multipleSelection)];
        let param = {
          idList: data,
        };
        // return
        this.axios.post("DELETE_USER", param).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getUserList();
        });
      });
    },
    // 获取标签样式
    getTagType(item) {
      if (item % 2 == 0) {
        return "new-tag1";
      } else if (item % 2 == 1) {
        return "new-tag2";
      } else if (item % 2 == 2) {
        return "new-tag3";
      } else if (item % 2 == 3) {
        return "new-tag4";
      }
    },
    // 获取用户列表
    getUserList() {
      this.axios
        .get("NEW_USER_LIST", {
          current: this.page.current,
          size: this.page.size,
          username: this.formInline.username,
          jobNumber: this.formInline.jobNumber,
        })
        .then((result) => {
          console.log("用户列表", result);
          this.tableData = result.data.records;
          this.page.total = result.data.total;
        });
    },
    search() {
      this.getUserList();
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
      this.getUserList();
    },
    // 切换到第几页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getUserList();
    },
  },
};
</script>

<style>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 20px;
  /* background: #F0F2F5; */
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-checkbox__label {
  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 20px;
}
</style>
